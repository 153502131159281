:root {
  --kcc-purple: #753bbd;
  --kcc-light-purple: #e3d8f2;

  --kcc-blue: #10069f;
  --kcc-light-blue: #bed2f3;

  --kcc-green: #33d196;
  --kcc-light-green: #92e7cc;

  --kcc-red: #ee3744;
  --kcc-light-red: #fcd7da;

  --cort-blue: #0072ce;

  --app-card-padding: clamp(.5rem, 2.5vw, 1.5rem) clamp(0px, 2.75vw, 2.5rem) clamp(0px, 2.75vw, 2.5rem) clamp(0px, 2.75vw, 2.5rem);
  --app-card-header-padding: .5rem clamp(0px, 2.5vw, 1rem);
}

* {
  font-family: "Gilroy" !important;
}

html {
  background-color: #e6e8ea !important;
}

a {
  text-decoration: none !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

/* .nav-link {
    padding: 0 0.5rem;
} */

.navbar-simulador {
  background-color: var(--cort-blue);
  color: #fff;
}

.navbar-simulador * {
  color: #fff;
}

.navbar-simulador li.nav-item a.nav-link {
  background-color: var(--cort-blue);
  color: #fff;
  font-weight: bold;
}

.navbar-simulador li.nav-item a.nav-link.disabled {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3vw;
  /* Set the fixed height of the footer here */
  background-color: #e6e8ea !important;
}

#main-content {
  background-color: #e6e8ea !important;
  padding: 1% !important;
}

.footer>.container {
  padding-right: 15px;
  padding-left: 15px;
}

#navbarTogglerDemo01 li.nav-item a.nav-link {
  color: #000;
  font-weight: bold;
  font-size: 14px;
}

#navbarTogglerDemo01 li.nav-item:hover a {
  color: var(--kcc-purple);
  font-weight: bold;
}

.searchbar {
  position: relative;
  min-width: 50px;
  width: 0%;
  height: 50px;
  float: right;
  overflow: hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
  transition: width 0.3s;
}

.searchbar-input {
  top: 0;
  right: 0;
  border: 0;
  background: transparent;
  border-radius: 10px;
  outline: none;
  width: 100%;
  height: 50px;
  margin: 0;
  padding: 0px 55px 0px 20px;
  font-size: 20px;
}

.searchbar-icon,
.searchbar-submit {
  width: 50px;
  height: 50px;
  display: block;
  position: absolute;
  top: 0;
  font-family: verdana;
  font-size: 22px;
  right: 0;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}

.searchbar-open {
  width: 100%;
}

.searchbar.searchbar-open .searchbar-input {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.btn-business-info {
  color: var(--kcc-blue) !important;
  border: 1px solid var(--kcc-blue);
  transition: 0.3s;
  cursor: pointer;
}

.btn-business-info.active,
.btn-business-info:hover {
  color: #fff !important;
  font-weight: bold;
  background-color: var(--kcc-blue) !important;
}

.btn-resource-info {
  color: var(--kcc-purple) !important;
  border: 1px solid var(--kcc-purple);
  transition: 0.3s;
  cursor: pointer;
}

.btn-resource-info.active,
.btn-resource-info:hover {
  color: #fff !important;
  font-weight: bold;
  background-color: var(--kcc-purple) !important;
}

.btn-partners-info {
  color: var(--kcc-green) !important;
  border: 1px solid var(--kcc-green);
  transition: 0.3s;
  cursor: pointer;
}

.btn-partners-info.active,
.btn-partners-info:hover {
  color: #fff !important;
  font-weight: bold;
  background-color: var(--kcc-green) !important;
}

.btn-sustainability-info {
  color: var(--kcc-red) !important;
  border: 1px solid var(--kcc-red);
  transition: 0.3s;
  cursor: pointer;
}

.btn-sustainability-info.active,
.btn-sustainability-info:hover {
  color: #fff !important;
  font-weight: bold;
  background-color: var(--kcc-red) !important;
}

.bg-business {
  color: #fff !important;
  background-color: var(--kcc-blue) !important;
}

.bg-resource {
  color: #fff !important;
  background-color: var(--kcc-purple) !important;
}

.bg-sustainability {
  color: #fff !important;
  background-color: var(--kcc-red) !important;
}

.bg-sustainability-col {
  color: #000 !important;
  /* background-color: hsl(0, 100%, 69%) !important; */
  background-color: var(--kcc-light-red) !important;
}

.bg-partners {
  color: #fff !important;
  background-color: var(--kcc-green) !important;
}

.radio-btn input[type="radio"] {
  display: none;
}

.radio-btn label {
  border: 2px solid #e6e8ea;
  border-radius: 5px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.radio-btn label::before {
  content: "";
  height: 16px;
  width: 16px;
  border: 3px solid #e6e8ea;
  background-color: #e6e8ea;
  border-radius: 50%;
  margin-right: 20px;
}

.radio-btn input[type="radio"]:checked+label {
  background-color: var(--kcc-light-blue);
  color: #000;
}

.bg-partners .radio-btn input[type="radio"]:checked+label {
  background-color: var(--kcc-light-green) !important;
}

.radio-btn:hover label {
  background-color: var(--kcc-light-blue);
  color: #000;
}

.bg-partners .radio-btn:hover label {
  background-color: var(--kcc-light-green) !important;
  color: #000;
}

.radio-btn input[type="radio"]:checked+label::before {
  height: 16px;
  width: 16px;
  border: 3px solid #e6e8ea;
  background-color: var(--kcc-blue);
}

.bg-partners .radio-btn input[type="radio"]:checked+label::before {
  border: 3px solid #e6e8ea;
  background-color: var(--kcc-green);
}

.list-topics .list-group-item,
.list-topics .list-group-item:hover {
  font-weight: bold;
  list-style-type: none;
  padding: 20px 5px;
  border: 0;
  border-top: 1px solid var(--kcc-blue);
  margin-bottom: 1px;
  position: relative;
}

.list-topics .list-group-item.active {
  color: #753bbc;
  background-color: inherit;
  border-color: inherit;
  border: 0;
  border-top: 1px solid var(--kcc-blue);
  /* border-left: 1px solid rgba(0,0,0,.125); */
}

.list-topics .list-group-item.active:hover {
  background-color: rgb(227, 216, 242, 0.2);
}

.list-topics .list-group-item.active::after {
  content: "\f105";
  font-family: FontAwesome;
  font-size: 19px;
  color: #753bbc;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.list-topics .list-group-item:last-child,
.list-topics .list-group-item:last-child:hover {
  border-bottom: 1px solid var(--kcc-blue);
}

.tooltip-resource-info {
  color: #000 !important;
  background-color: var(--kcc-light-purple) !important;
  opacity: 0.96 !important;
}

.tooltip-resource-info.place-top::after {
  border-top-color: var(--kcc-light-purple) !important;
}

.tooltip-resource-info.place-right::after {
  border-right-color: var(--kcc-light-purple) !important;
}

.tooltip-resource-info.place-left::after {
  border-left-color: var(--kcc-light-purple) !important;
}

.tooltip-resource-info.place-bottom::after {
  border-bottom-color: var(--kcc-light-purple) !important;
}

.tooltip-sustainability-info {
  color: #000 !important;
  background-color: var(--kcc-light-red) !important;
  opacity: 0.96 !important;
}

.tooltip-sustainability-info.place-top::after {
  border-top-color: var(--kcc-light-red) !important;
}

.tooltip-sustainability-info.place-right::after {
  border-right-color: var(--kcc-light-red) !important;
}

.tooltip-sustainability-info.place-left::after {
  border-left-color: var(--kcc-light-red) !important;
}

.tooltip-sustainability-info.place-bottom::after {
  border-bottom-color: var(--kcc-light-red) !important;
}

.was-validated .ValidateSelect *:invalid~div {
  border-color: var(--kcc-red);
}

.was-validated .ValidateSelect:has(*:invalid)~.invalid-feedback {
  display: block;
}

.ValidateSelect *:invalid~div,
.ValidateSelect *:valid~div {
  border-color: hsl(0, 0%, 80%);
  box-shadow: none;
}

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid,
.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: hsl(0, 0%, 80%);
}

.validation-set .invalid-feedback {
  padding-left: 0.15rem !important;
  margin-top: 0 !important;
}

.was-validated .bg-partners .validation-set:has(*:invalid) {
  background-color: var(--kcc-light-green);
  border-radius: 0.25rem !important;
}

.was-validated .bg-resource .validation-set:has(*:invalid) {
  background-color: var(--kcc-light-purple);
  border-radius: 0.25rem !important;
}

.was-validated .bg-business .validation-set:has(*:invalid) {
  background-color: var(--kcc-light-blue);
  border-radius: 0.25rem !important;
}

.was-validated .bg-sustainability .validation-set:has(*:invalid) {
  background-color: var(--kcc-light-red);
  border-radius: 0.25rem !important;
}

.custom-hover-page {
  opacity: 0.8;
  background-color: var(--kcc-light-purple);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

.loading-element {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  height: 130px;
  margin: auto;
  width: 130px;
}

.navbar-brand {
  margin-left: 10px;
}

.navbar {
  padding: 1px 1% 0px 1% !important;
}

.enrichTable {
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  font: normal 13px Arial, sans-serif;
  background: "#f0f0f5" !important;

  letter-spacing: 0 !important;
}

.enrichTable thead {
  background-color: #000 !important;
  color: #ffffff !important;
  /* color: #ffffff;
  -webkit-border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  -webkit-box-shadow: 3px 5px 10px grey;
  -moz-box-shadow: 3px 5px 10px grey;
  box-shadow: 3px 5px 10px grey; */
}

.enrichTable tbody th {
  text-align: center !important;
  padding: 0px !important;
}

.enrichTable thead tr:nth-child(1) th:nth-child(1),
.enrichTable thead tr:nth-child(4) th:nth-child(1),
.enrichTable thead tr:nth-child(4) th:nth-child(2),
.enrichTable thead tr:nth-child(4) th:nth-child(3) {
  /* color: #ffffff; */
  z-index: 1003 !important;
}

.enrichTable thead tr {
  position: sticky;
  z-index: 1011;
}

.enrichTable thead tr:nth-child(1) {
  z-index: 1012;
  top: 0;
}

.enrichTable thead tr:nth-child(2) {
  top: 29px;
}

.enrichTable thead tr:nth-child(3) {
  top: 58px;
}

.enrichTable thead tr:nth-child(4) {
  top: calc(58px + 29px);
}

.enrichTable thead tr:nth-child(1) tr:nth-child(2) tr:nth-child(3) th {
  white-space: nowrap;
}

.enrichTable thead th {
  background-color: #0072ce !important;
  color: #ffffff !important;
  z-index: 1002 !important;
  /* white-space: break-spaces; */
  font-size: 14px;
  padding: 2px;
  text-align: center !important;
  /* padding: 0px !important; */
  /* background-color: #ddefef; */
  /* border: none;
  
  color: #336b6b;
  padding: 10px;
  text-align: left; */
  /* border: solid 1px #000; */
  /* color: #333; */

  /* text-shadow: 1px 1px 1px #fff; */
}

.enrichTable tbody td {
  /* border: solid 1px #000; */
  /* color: #333; */
  /* padding: 10px; */
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
}

.enrichTable input {
  height: 0.5em;
  font-size: 12px;
}

.enrichTable td:nth-child(3) {
  /* color: #ffffff; */
  font-size: 12px;

  box-shadow: 3px 5px 5px grey;
}

.enrichTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.enrichTable .MuiInputBase-input {
  padding: 8.5px 8px;
}

.sticky-column {
  position: "sticky" !important;
  left: 0 !important;
  background: "#000" !important;
  color: "#ffffff" !important;
  z-index: 1000 !important;
}

.enrichTable tr:nth-child(even) td {
  background-color: #f2f2f2;
}

.enrichTable tr:nth-child(odd) td {
  background-color: #fff;
}

.MuiTablePagination-toolbar p {
  margin: auto;
}

.numericInput input {
  text-align: end;
}

.numericInputStandart input {
  text-align: end;
}

.numericInputStandart fieldset {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid black;
}

.css-1y8ugea {
  margin-top: 0vh !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #e6e8ea;
}

::-webkit-scrollbar-thumb {
  background: #989b9b;
  border-radius: 16px;
}
body {
    margin: 0;
    padding: 0;
}

#main-content {
    background-color: #e6e8ea !important;
    padding: 8px !important;
}

#map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}

.calculation-box {
    height: 75px;
    width: 150px;
    position: absolute;
    bottom: 40px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 15px;
    text-align: center;
}

p {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    margin: 0;
    font-size: 13px;
}

.container-body{
    font-family: 'Gilroy-ExtraBold', sans-serif;
    font-size: 16px;
    color: var(--cort-blue);

    background: white;
    padding: 15px;
    margin-top: 2%;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 20px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    font-weight: bolder;
}

.label
{
    font-family: 'Gilroy-ExtraBold', sans-serif;
    text-align: center;
}

.table
{
    font-family: 'Gilroy-ExtraBold', sans-serif;
    text-align: center;
    width: 40%;
    bottom: 0;
    top: 0;
    left: 0;
    overflow-x: hidden;
}
.tools {
    font-family: "Gilroy";
    position: absolute;
    padding: 4px;
    background: rgb(255, 255, 255);
    color: #0072ce;
    max-width: 420px;
    font-size: 12px;
    line-height: 16px;
    z-index: 9;
    pointer-events: none;
}

.sketch-picker {
    width: 100% !important;
    background: none !important;
    box-shadow: none !important;
    padding: 0px !important;
}